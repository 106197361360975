import { Component } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from 'plugins/cordova-plugin-fcm-with-dependecy-updated/ionic/ngx/FCM';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private fcm: FCM,
    private alert: AlertController,
    private nav: NavController,
    private router: Router,
    private user: UserService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp(){
    this.platform.ready().then(() => {

      this.fcm.requestPushPermission()
      .then( (permission) => {
        this.fcm.getToken().then(token => {});
      })
      .catch((err) => {} );

      this.fcm.hasPermission()
      .then(hasPermission => {if (hasPermission) {} });

      this.fcm.subscribeToTopic('all')
      .then( () => console.log('suscrito a all') )
      .catch( (error) => console.log('[ERROR]subscribeToTopic ALL' + JSON.stringify(error) ) );

      this.fcm.onNotification()
      .subscribe(
        (data) => {

          if (data.wasTapped){
            this.router.navigateByUrl(data.component + '/' + data.id);
          } else {
            this.presentAlert(data);
          }

        },
        (error) => {}
      );

      this.user.promiseAuth()
      .then( (data) => {

        this.nav.navigateRoot('tabs')
        .then( () => console.log('navigate ok') )
        .catch( (err) => console.log('navigate ko ' + JSON.stringify(err)));

        this.splashScreen.hide();
      })
      .catch( (er) => {
        console.log('promise KO ' + JSON.stringify(er));
        this.splashScreen.hide();
      })
      .finally( () => {
        console.log('FINALLY HIDE');
        this.splashScreen.hide();
      });

      this.statusBar.styleDefault();

    });
  }

  async presentAlert(data) {
    const alert = await this.alert.create({
      header: data.title,
      message: data.body,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {}
        }, {
          text: 'OK',
          handler: () => { this.router.navigateByUrl(data.component + '/' + data.id); }
        }
      ]
    });

    await alert.present();
  }
}
