import { Injectable } from '@angular/core';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { DataUser, environment, PDF } from 'src/environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public data: DataUser;
  // public auth = { email : 'cristianvazquez93@gmail.com', password : 'Admin23#2020' };
  public auth = { email : '', password : '' };
  public isLoggedIn = false;

  public notifications: any[] = [];

  public fileTransfer: FileTransferObject;

  constructor(
    public toastCtrl: ToastController,
    private nav: NavController,
    private iab: InAppBrowser,
    private platform: Platform,
    private storage: NativeStorage,
    private transfer: FileTransfer,
    private socialSharing: SocialSharing,
    private file: File
  ) {}

  async generateToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  // START AUTH HELPERS
  promiseAuth(){
    return new Promise( (resolve, reject) => {
      this.storage.getItem('backup')
        .then(
          (data) => {
            this.auth = data['auth'];
            this.data = data['data'];
            this.isLoggedIn = true;
            resolve(data);
          },
          (error) => reject(error))
        .catch( (er) => reject(er) );
    });
  }

  GetHour(offset=1){
    let date = new Date();
    return new Date(new Date(date).setHours(date.getHours() + offset));
  }

  setAuth(paramData: DataUser){
    this.data = paramData;
    this.storage.setItem('backup', { auth: this.auth, data: paramData})
      .then(
        () => {
          console.log('Stored item!');
          this.isLoggedIn = true;
        },
        error => console.error('Error storing item ->' + JSON.stringify(error))
      );
  }

  resetAuth(){
    this.storage.clear()
      .then( () => {
        this.isLoggedIn = false;
        this.data = undefined;
        this.auth = { email : '', password : '' };
        this.nav.navigateRoot('login');
      })
      .catch( () => this.generateToast('No se ha podido cerrar sesión'));
  }
  // END AUTH HELPERS

  url(url){
    this.iab.create(url, '_system');
  }

  whatsap(tlf, txt){
    this.iab.create('https://wa.me/' + tlf + '?text=' + txt, '_system');
  }

  gotoPassword(){
    this.url(environment.reset);
  }

  getGuest(): DataUser{
    return {
      user: {
        active: 1,
        avatar: 'https://planifikator.com/images/avatar/avatar_12422_cristian_20201205115646.jpg',
        cod_user: null,
        created_at: '2020-10-02T06:35:37.000000Z',
        email: 'cristianvazquez93@gmail.com',
        id: 2,
        last_name: 'Vázquez Casanova',
        name: 'Cristian',
        opinion: null,
        phone: '685098295',
        referred_by: null,
        referrer_code: 'qQJprQ',
        updated_at: '2020-12-15T13:10:09.000000Z',
        verification_sms_code: '0'
      },
      token : 'FASFASDFASFDSA53'
    };
  }

  // FILTER LIST
  filterItems(searchTerm, items){
    return items.filter((item) => {
     return item.description.toLowerCase().
      indexOf(searchTerm.toLowerCase()) > -1 ||
        item.title.toLowerCase().
         indexOf(searchTerm.toLowerCase()) > -1;
    });
  }
  
  filterContact(searchTerm,items){
    return items.filter((item) => {
      return item.name.toLowerCase().
       indexOf(searchTerm.toLowerCase()) > -1;
     });
  }

  download(pdf: PDF) {
    console.log(pdf);
    if ( !pdf) { return false; }

    this.fileTransfer = this.transfer.create();

    let path = null;

    if ( this.platform.is('ios') ){
      path = this.file.documentsDirectory;
    }

    if ( this.platform.is('android') ){
      path = this.file.documentsDirectory;
    }

    if ( !this.platform.is('ios') && !this.platform.is('android') ){
      return this.url(pdf.link);
    }

    path += pdf.filename;

    this.fileTransfer.download(pdf.link, path)
    .then(
      (entry) => {

        console.log('download complete: ' + entry.toURL());

        this.socialSharing.share(pdf.message, pdf.subject, path, null)
        .then( () => console.log('Compartido!'))
        .catch( (er) => console.log('CATCH socialSharing', er));

      },
      (error) => {

       console.log('ERROR -> ', error);

      })
    .catch( (er) => console.log('CATCH -> ', er));
  }

}
