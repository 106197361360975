import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from './guard/auth.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthService]},
  { path: 'register', loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule) },
  { path: 'login', loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule) },
  { path: 'add-note', loadChildren: () => import('./modals/add-note/add-note.module').then( m => m.AddNotePageModule), canActivate: [AuthService] },
  { path: 'notes', loadChildren: () => import('./pages/notes/notes.module').then( m => m.NotesPageModule), canActivate: [AuthService] },
  { path: 'notes/:id_proceeding', loadChildren: () => import('./pages/notes/notes.module').then( m => m.NotesPageModule), canActivate: [AuthService] },
  { path: 'incidences', loadChildren: () => import('./pages/incidences/incidences.module').then( m => m.IncidencesPageModule), canActivate: [AuthService] },
  { path: 'incidences/:id_proceeding', loadChildren: () => import('./pages/incidences/incidences.module').then( m => m.IncidencesPageModule), canActivate: [AuthService] },
  { path: 'reminders', loadChildren: () => import('./pages/reminders/reminders.module').then( m => m.RemindersPageModule), canActivate: [AuthService] },
  { path: 'reminders/:id_proceeding', loadChildren: () => import('./pages/reminders/reminders.module').then( m => m.RemindersPageModule), canActivate: [AuthService] },
  { path: 'add-reminder/:id_reminder', loadChildren: () => import('./modals/add-reminder/add-reminder.module').then( m => m.AddReminderPageModule), canActivate: [AuthService] },
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule), canActivate: [AuthService] },
  { path: 'add-incidence', loadChildren: () => import('./modals/add-incidence/add-incidence.module').then( m => m.AddIncidencePageModule), canActivate: [AuthService] },
  { path: 'fakesplashscreen', loadChildren: () => import('./pages/fakesplashscreen/fakesplashscreen.module').then( m => m.FakesplashscreenPageModule)},
  {
    path: 'view-tip',
    loadChildren: () => import('./modals/view-tip/view-tip.module').then( m => m.ViewTipPageModule)
  },  {
    path: 'select-contact',
    loadChildren: () => import('./modals/select-contact/select-contact.module').then( m => m.SelectContactPageModule)
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
