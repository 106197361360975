import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  constructor(
    private router: Router,
    private user: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      if (this.user.isLoggedIn) { return true; }
      
      if ( this.user.data !== undefined ){ return true; }

      return this.user.promiseAuth()
      .then( () => { 
        console.log('promiseAuth -> OK TRUE');
        return true; 
      })
      .catch( (er) => {
        console.log('promiseAuth -> CATCH -> ' + JSON.stringify(er));
        this.router.navigate(['/login']);
        return false;
      });
  }
}