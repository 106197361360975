// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://www.planifikator.com/api/',
  reset : 'https://www.planifikator.com/password/rest'
};

export interface PDF{
  filename: string;
  link: string;
  message: string;
  subject: string;
}

export interface Req{
  status: number;
  data: any[];
}

export const loadOpt = {
  message: 'Cargando...',
  duration: 6500
};

export interface Auth{
  email: any;
  pass: any;
}

export interface Register{
  email: string;
  name: string;
  pass_repeat: string;
  pass: string;
  phone: string;
}

export interface User{
  id: number;
  email: string;
  active: number;
  avatar: string;
  created_at: string;
  name: string;
  opinion: string;
  phone: string;
  updated_at: string;
  cod_user: number;
  last_name: string;
  referred_by: null;
  referrer_code: string;
  verification_sms_code: string;
}

export interface DataUser {
  user: User;
  token: string;
}

export interface Note{
  id_proceeding: any;
  type: number;
  title: string;
  description: string;
  todo: any[];
}

export interface Incidence{
  id_proceeding: any;
  also_notify_contact: boolean;
  contact_name: string;
  contact_id: any;
  new_contact: string;
  new_contact_email: string;
  type: number;
  title: string;
  description: string;
  date: any;
  hour: any;
}

export interface Reminder{
  id_proceeding: any;
  date: any;
  type: number;
  title: string;
  also_notify_contact: boolean;
  contact_id: any;
  new_contact: string;
  new_contact_email: string;
  hour: any;
  description: string;
}

export interface Contact{
  id_contact: number;
  name: string;
}



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
